<template>
	<div class="layout">
		<h2 class="layout__title">{{ $t('ads.layoutTitle') }}</h2>

		<div v-if="!isNotTags" class="layout__controls">
			<ul class="layout__tags layout-tags">
				<template v-for="tag in tagList" :key="tag.value">
					<li v-if="tag.isShow">
						<button
							type="button"
							class="btn layout-tags__btn"
							@click="setActiveType(tag.value)"
							:class="{ 'layout-tags__btn--active': tag.value === active }"
						>
							{{ $t(tag.title) }}
						</button>
					</li>
				</template>
			</ul>
		</div>

		<or-slider
			v-slot="{ item }"
			:collectionData="content"
			:sliderProperties="{ pagination: true }"
			:breakpoints="carouselSettings.breakpoints"
			@cardClick="openImage"
		>
			<or-image
				class="layout-carousel__image"
				:src="item"
				:width="198"
				loading="eager"
				:height="139"
			/>
		</or-slider>
	</div>
</template>

<script>
export default {
	props: {
		isNotTags: {
			type: Boolean,
			default: false
		},
		layout: {
			type: Array,
			default: () => []
		},
		floorPlan: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			currentSlide: 0,
			active: ''
		}
	},
	beforeMount() {
		if (this.layout?.length) {
			this.active = 'layout'

			return
		}

		if (this.floorPlan?.length) {
			this.active = 'floorPlan'
		}
	},
	computed: {
		content() {
			return this.active ? this[this.active] : []
		},
		carouselSettings() {
			return {
				breakpoints: {
					320: { itemsToShow: 1, snapAlign: 'start' },
					700: { itemsToShow: 2, snapAlign: 'start', touchDrag: true },
					1200: { itemsToShow: 4, snapAlign: 'start', touchDrag: false }
				}
			}
		},

		tagList() {
			return [
				{
					title: 'ads.layoutTitle',
					value: 'layout',
					isShow: this.layout?.length
				},
				{
					title: 'ads.floorPlan',
					value: 'floorPlan',
					isShow: this.floorPlan?.length
				}
			]
		}
	},
	methods: {
		setActiveType(value) {
			this.active = value
		},
		openImage(index) {
			this.$store.commit('showModal', {
				name: 'PlaceCarousel',
				props: {
					items: [...this.content],
					index
				}
			})
		}
	}
}
</script>

<style lang="scss">
.layout {
	position: relative;
	&__title {
		margin: 0;
		margin-bottom: 20px;
		font-size: 18px;
		font-weight: 700;
	}

	&__controls {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 30px;
	}

	&__image {
		min-height: 139px;
	}

	.layout-tags {
		display: flex;
		gap: 10px;

		&__btn {
			padding: 10px;
			font-size: 16px;
			line-height: 24px;
			border-radius: 4px;
			border: 1px solid rgba(0, 0, 0, 0.1);

			&--active {
				color: #c99d56;
				border-color: #c99d56;
			}
		}
	}

	.boots-slider__slide {
		cursor: pointer;
	}

	.boots-slider__pagination {
		position: absolute;
		top: 50px;
		right: 0;
	}
}
</style>
