<template>
	<div class="solo-ads">
		<div class="container">
			<Breadcrumb
				:pages="pages"
				:is-like="true"
				:info="infoAds"
				class="solo-ads__breadcrumb"
			/>

			<Images
				v-if="!isLoad"
				:images="infoAds.images"
				:video="infoAds.video"
				class="solo-ads__images"
			/>
			<or-skeleton v-else class="solo-ads__images--skeleton" />

			<Title
				:content="infoAds"
				:is-load="isLoad"
				type="ads"
				class="solo-ads__block"
			/>
			<Immobilien
				:content="infoAds"
				:is-load="isLoad"
				:plan="infoAds.plan"
				:address="infoAds.address"
				:licence="infoAds.licence"
				class="solo-ads__block"
			/>

			<Showcase
				v-if="!isLoad"
				:content="infoAds"
				class="solo-ads__block solo-ads__block--showcase"
			/>
			<or-skeleton v-else class="solo-ads__block--skeleton-showcase" />

			<template v-if="!isLoad">
				<Tags
					v-if="featuresList?.length"
					:title="$t('ads.features')"
					:content="featuresList"
					class="solo-ads__block solo-ads__block--tags"
				/>
			</template>
			<or-skeleton v-else class="solo-ads__block--skeleton" />

			<template v-if="!isLoad">
				<Tags
					v-if="externalInfrastructureList?.length"
					:title="$t('ads.externalInf')"
					:content="externalInfrastructureList"
					class="solo-ads__block solo-ads__block--tags"
				/>
			</template>
			<or-skeleton v-else class="solo-ads__block--skeleton" />

			<template v-if="!isLoad">
				<Tags
					v-if="internalInfrastructureList?.length"
					:title="$t('ads.iternalInf')"
					:content="internalInfrastructureList"
					class="solo-ads__block solo-ads__block--tags"
				/>
			</template>
			<or-skeleton v-else class="solo-ads__block--skeleton" />

			<template v-if="!isLoad">
				<Layout
					v-if="infoAds.layout?.length || infoAds.floor_plan?.length"
					:layout="infoAds.layout"
					:floorPlan="infoAds.floor_plan"
					class="solo-ads__block solo-ads__block--end"
				/>
			</template>
			<or-skeleton v-else class="solo-ads__block--skeleton-layout" />

      <Gallery
        class="solo-project__gallery"
        v-if="adsList.items?.length"
        :title="$t('web.similar_property')"
        :content="adsList.items"
        :options="{ type: 'EstateCard', alt: 'Estate logo' }"
        card="EstateCard"
        @cardClick="redirect"
      />
		</div>
	</div>
</template>

<script>
import {ref, computed, watch} from 'vue'
import { useStore } from 'vuex'
import {useRoute, useRouter} from 'vue-router'

import { i18n } from '@/i18n'
import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'
import { setCommonMetaData } from '@/utils/setMetaData'

import Breadcrumb from '@/components/Breadcrumb'
import Images from '@/components/SoloPages/Images'
import Immobilien from '@/components/SoloPages/Immobilien'
import Title from '@/components/SoloPages/TitleLine'
import Showcase from '@/components/SoloPages/Showcase'
import Tags from '@/components/SoloPages/Tags'
import Layout from '@/components/SoloPages/Layout.vue'
import SpecialOffers from '@/components/SoloPages/SpecialOffers'
import Gallery from "@/components/Gallery.vue";

export default {
	name: 'SoloAds',
	components: {
    Gallery,
		Breadcrumb,
		Images,
		Title,
		Tags,
		Layout,
		Showcase,
		Immobilien,
		SpecialOffers
	},
	setup() {
		const infoAds = ref({})
		const isLoad = ref(true)
    const adsList = ref({})

    const store = useStore()
		const { commit } = useStore()
		const route = useRoute()
    const router = useRouter()

    _load()

    function _load() {
      createRequest(requestConfigs.GETInfoAds, {
        routerPayload: {slug: route.params.slug}
      })
        .then((result) => {
          infoAds.value = {...result.response}

          setCommonMetaData(
            `${infoAds.value.full_name || infoAds.value.title} - OWRealty`,
            {
              description: i18n.global.t('meta.adDesc'),
              type: 'article',
              title: `${
                infoAds.value.full_name || infoAds.value.title
              } - OWRealty`,
              url: window.location.href
            }
          )
        })
        .catch((err) => commit('setError', {status: err.errCode || 404}))
        .finally(() => (isLoad.value = false))

      createRequest(requestConfigs.GETSearch, {
        queryPayload: {
          relate_to: route.params.slug
        }
      }).then((result) => (adsList.value = result.response))
      scrollTo({top: 0, behavior: 'smooth'})
    }

		const pages = computed(() => ({
			first: {
				title: i18n.global.t('general.search'),
				srcName: 'Search'
			},
			current: infoAds.value?.title
		}))

    const redirect = (_, payload) => {
      router.push({
        name: 'SoloAds',
        params: { slug: payload.slug, lang: store.state.uiLanguage }
      })
    }

		const featuresList = computed(
			() =>
				infoAds.value?.amenity?.filter((el) => el.type === 'features')[0]?.name
		)
		const externalInfrastructureList = computed(
			() =>
				infoAds.value?.amenity?.filter((el) => el.type === 'exterenal')[0]?.name
		)
		const internalInfrastructureList = computed(
			() =>
				infoAds.value?.amenity?.filter((el) => el.type === 'internal')[0]?.name
		)

    watch(() => [route.name, route.params.slug], (next, prev) => {
      if (next[0] !== prev[0]) return
      _load()
    })

		return {
			isLoad,

			pages,
			infoAds,
      adsList,
			featuresList,
			externalInfrastructureList,
			internalInfrastructureList,
      redirect
		}
	}
}
</script>

<style lang="scss">
.solo-ads {
	padding-bottom: 50px;

	&__breadcrumb {
		margin-bottom: 30px;
	}

	&__images {
		margin-bottom: 40px;

		&--skeleton {
			height: 380px;
			width: 100%;
			margin-bottom: 40px;

			@media screen and (max-width: 768px) {
				height: 300px;
			}
		}
	}

	&__block {
		padding-bottom: 30px;
		margin-bottom: 30px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);

		&--skeleton {
			width: 100%;
			height: 110px;
			margin-bottom: 30px;
		}

		&--skeleton-showcase {
			width: 100%;
			height: 80px;
			margin-bottom: 30px;
		}

		&--skeleton-layout {
			width: 100%;
			height: 390px;
			margin-bottom: 30px;
		}

		&--skeleton-offers {
			width: 100%;
			height: 210px;
		}

		&--tags {
			padding-bottom: 20px;
		}
		&--end {
			border-bottom: unset;
			padding-bottom: 0;
		}
	}

	@media screen and (max-width: 768px) {
		padding-bottom: 40px;

		&__block {
			&--showcase {
				padding-bottom: 0;
				border-bottom: none;
			}
		}
	}
}
</style>
